import css from '@utils/css';
import useMods from '@hooks/useMods';

import Image from 'next/future/image';
import PropTypes from 'prop-types';
import React from 'react';

const shimmer = (width, height, theme) => `
    <svg width="${ width }" height="${ height }" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <linearGradient id="gradient" gradientTransform="rotate(10)">
                <stop stop-color="${ theme === 'dark' ? '#3F3D52' : '#FCFCFC' }" offset="20%" />
                <stop stop-color="${ theme === 'dark' ? '#434157' : '#F7F7FF' }" offset="50%" />
                <stop stop-color="${ theme === 'dark' ? '#3F3D52' : '#FCFCFC' }" offset="70%" />
            </linearGradient>
        </defs>
        <rect fill="${ theme === 'dark' ? '#3F3D52' : '#FCFCFC' }" width="${ width }" height="${ height }" />
        <rect id="r" width="${ width }" height="${ height }" fill="url(#gradient)" />
        <animate xlink:href="#r" attributeName="x" from="-${ width }" to="${ width }" dur="1s" repeatCount="indefinite" />
    </svg>
`;

const toBase64 = (str) =>
    typeof window === 'undefined'
        ? Buffer.from(str).toString('base64')
        : window.btoa(str);

const Picture = ({ children, mods, image, sizes, priority, theme, alt, order, props, className: classNameTail, imageClassName }) => {
    const { className } = useMods(Picture, { mods: { ...mods, order } });

    return (
        <div
            className={ css(className, classNameTail) }
        >
            <Image
                src={ image }
                placeholder='blur'
                blurDataURL={ `data:image/svg+xml;base64,${ toBase64(shimmer(image.width, image.height, theme)) }` }
                sizes={ sizes }
                loading={ priority ? 'eager' : 'lazy' }
                quality={ 85 }
                alt={ alt }
                priority={ priority }
                className={ imageClassName }
                { ...props }
            />
            { children }
        </div>
    );
};

Picture.displayName = 'Picture';

Picture.propTypes = {
    alt: PropTypes.string.isRequired,
    image: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]).isRequired,
    mods: PropTypes.object,
    order: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),
    priority: PropTypes.bool,
    sizes: PropTypes.string,
    theme: PropTypes.string,
    props: PropTypes.object
};

Picture.defaultProps = {
    mods: {},
    priority: false,
    sizes: '(min-width: 1280px) 50vw, (min-width: 1024px) 40vw, (min-width: 768px) 45vw, (min-width: 425px) 50vw, 20vw',
    theme: 'light'
};

export default Picture;
